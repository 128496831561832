<template>
  <span class="edit-payment" v-if="payment && hasPerm('payments.change_payment')">
    <b-modal dialog-class="modal-xl"
      :id="getId()"
      cancel-title="Annuler"
      :ok-disabled="isPayDisabled()"
      @ok.prevent="onHidden"
      @hidden="onHidden"
      ok-variant="secondary"
      ok-title="Fermer"
    >
      <template v-slot:modal-title>
        <b><i class="fa fa-edit"></i> Modifier un paiement</b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <div class="warning2-text">
        <b>
          <i class="fa fa-warning"></i> Attention! La modification ou la suppression d'un paiement
          aura un impact sur les avoirs liés et sur la comptabilité analytique.<br />
          Ne modifiez ou ne supprimez un paiement qu'en cas d'erreur manifeste.
          <br />
        </b>
      </div>
      <b-row>
        <b-col cols="9">
          <b-row class="">
            <b-col md="4">
              <b-form-group
                id="payment-date-group"
                label="Date de retrait"
                label-for="payment-date"
                description="Pour un chèque indiquer quand il doit être retiré"
              >
                <b-form-input type="date" id="payment-date" v-model="paymentDate">
                </b-form-input>
                <div v-if="!paymentDate" class="error-field">
                  La date est manquante
                </div>
                <div v-else-if="!checkPaymentDate(paymentDate)" class="error-field">
                  Cette date semble invalide
                </div>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <div style="margin-bottom: 8px"><b>Montant</b></div>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="amount-group"
                    label=""
                    label-for="amount"
                    description="Initial"
                  >
                    <b-form-input id="amount" type="text" :value="payment.amount | currency" disabled>
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="new-amount-group"
                    description="Nouveau"
                    label-for="new-amount"
                    required
                  >
                    <decimal-input id="new-amount" v-model="amount"></decimal-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="4">
              <b-form-group
                id="payment-modes-group"
                label="Mode"
                label-for="payment-modes"
                description="Mode de paiement"
              >
                <b-form-select
                  id="payment-modes"
                  v-model="selectedPaymentMode"
                  required
                  @change="onPaymentModeChanged()"
                >
                  <b-form-select-option
                    :value="item"
                    v-for="item in paymentModes"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <b-form-group
                id="emitter-group"
                label="Émetteur"
                label-for="emitter"
                description="Si différent de la famille"
              >
                <b-form-input
                  id="emitter"
                  v-model="emitter"
                  :disabled="!selectedPaymentMode.showEmitter"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                id="bank-group"
                label="Banque"
                label-for="bank-name"
              >
                <vue-bootstrap-typeahead
                  id="bank-name"
                  v-model="bankName"
                  :data="banks"
                  :disabled="!selectedPaymentMode.bank"
                  :required="selectedPaymentMode.bank"
                  ref="bankNameTypeAhead"
                  @hit="onBankSelected"
                  :minMatchingChars="2"
                  :disableFiltering="true"
                >
                </vue-bootstrap-typeahead>
                <div v-if="allowMoreBanks">
                  <a href @click.prevent="showMoreBanks" class="small2">
                    Voir plus de banques
                  </a>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                id="bank-number-group"
                label="Numéro"
                label-for="bank-number"
                :description="selectedPaymentMode.isNumberRequired ? 'requis': ''"
              >
                <b-form-input
                  id="bank-number"
                  v-model="bankNumber"
                  :required="selectedPaymentMode.bank && selectedPaymentMode.isNumberRequired"
                  :disabled="!selectedPaymentMode.bank"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                id="comments-group"
                label="Commentaires"
                label-for="comments"
              >
                <b-form-input id="comments" v-model="comments"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3">
          <div class="modal-line">
            Créé par {{ payment.createdBy | defaultValue('-') }}
          </div>
          <div class="modal-line" v-if="deposit && deposit.id">
            Dépôt {{ deposit.depositOn | dateToString }} <span v-if="deposit.number">- N°{{ deposit.number }}</span>
          </div>
          <div class="modal-line" v-else>
            non déposé
          </div>
          <div class="modal-line" v-for="invoice of invoices" :key="invoice.id">
            Facture {{ invoice.getNumber() }}
          </div>
          <div class="modal-line" v-if="paymentEntity.name">
            {{ paymentEntity.name }}
          </div>
        </b-col>
      </b-row>
      <template #modal-footer v-if="payment">
        <div class="footer-buttons">
          <a class="btn-sm btn btn-danger" @click.prevent="onDelete()" v-if="canDeletePayment">
            Supprimer
          </a>
          <a class="btn-sm btn btn-primary" @click.prevent="onModify()" v-if="canChangePayment">
            Modifier
          </a>
          <a class="btn-sm btn btn-secondary" @click.prevent="onHidden()">
            Annuler
          </a>
        </div>
      </template>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import DecimalInput from '@/components/Controls/DecimalInput'
import { BackendMixin } from '@/mixins/backend'
import { makePaymentMode } from '@/types/payments'
import { getRandomId } from '@/utils/random'
import { BackendApi } from '@/utils/http'

export default {
  name: 'edit-payment',
  components: { DecimalInput, },
  mixins: [BackendMixin],
  props: {
    payment: Object,
    entity: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      randomId: getRandomId(),
      amount: 0,
      banks: [],
      allBanks: [],
      allowMoreBanks: false,
      paymentModes: [],
      selectedPaymentMode: makePaymentMode(),
      comments: '',
      emitter: '',
      bankName: '',
      bankNumber: '',
      errorText: '',
      paymentDate: null,
    }
  },
  computed: {
    deposit() {
      return this.payment.deposit
    },
    paymentEntity() {
      return this.entity || this.payment.entity
    },
    amountBefore() {
      return -1
      // return this.paymentInvoice ? this.paymentInvoice.toBePaidPrice() : 0
    },
    fullAmount() {
      let fullAmount = +this.amount - this.payment.amount
      return Math.round(fullAmount * 100) / 100
    },
    amountAfter() {
      return Math.max(0, this.amountBefore - this.fullAmount)
    },
    futureCredit() {
      return Math.round((this.fullAmount - this.amountBefore) * 100) / 100
    },
    invoices() {
      if (this.payment.invoices) {
        return this.payment.invoices
      } else {
        return this.payment.contributions.map(contrib => contrib.invoice)
      }
    },
    canChangePayment() {
      return this.hasPerm('payments.change_payment')
    },
    canDeletePayment() {
      return this.hasPerm('payments.delete_payment')
    },
  },
  watch: {
    payment: function() { this.init() },
    selectedPaymentMode: function() {
      if (!this.selectedPaymentMode.bank) {
        this.bankName = ''
        this.bankNumber = ''
      }
      if (!this.selectedPaymentMode.s) {
        this.emitter = ''
      }
    },
    amountBefore: function(newValue, oldValue) {},
    amountAfter: function(newValue, oldValue) {},
    text: function(newValue, oldValue) {},
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    init() {
      if (this.payment) {
        this.amount = this.payment.amount
        this.comments = this.payment.comments
        const paymentDate = this.payment.paymentDate || this.payment.createdOn
        if (paymentDate) {
          this.paymentDate = moment(paymentDate).format('YYYY-MM-DD')
        }
        this.emitter = this.payment.emitter
        this.bankName = this.payment.bankName
        this.$refs.bankNameTypeAhead.inputValue = this.bankName
        this.bankNumber = this.payment.bankNumber
        this.selectedPaymentMode = this.payment.paymentMode
      }
    },
    async showModal() {
      this.$bvModal.show(this.getId())
      await this.loadPaymentModes()
      this.init()
    },
    getId() {
      return 'bv-modal-edit-payment' + this.randomId
    },
    isPayDisabled() {
      if (this.paymentDate === null) {
        return false
      }
      if (this.selectedPaymentMode.id === 0) {
        return false
      } else {
        let isOk = true
        if (this.selectedPaymentMode.bank) {
          isOk = this.bankName.length > 0
        }
        if (isOk && this.selectedPaymentMode.isNumberRequired) {
          isOk = this.bankNumber.length > 0
        }
        if (!isOk) {
          return true
        }
      }
      return false
    },
    onHidden() {
      this.$emit('hidden')
    },
    async onDelete() {
      this.errorText = ''
      try {
        const url = '/api/delete-payment/' + this.payment.id + '/'
        const backendApi = new BackendApi('delete', url)
        await backendApi.callApi()
        this.$emit('done')
        this.$bvModal.hide(this.getId())
        await this.addSuccess('Le paiement a été supprimé')
      } catch (err) {
        this.errorText = this.getErrorText(err)
      }
    },
    async onModify() {
      this.errorText = ''
      try {
        const url = '/api/edit-payment/' + this.payment.id + '/'
        const backendApi = new BackendApi('post', url)
        let data = {
          amount: this.amount ? this.amount : 0,
          payment_mode: this.selectedPaymentMode.id ? this.selectedPaymentMode.id : null,
          comments: this.comments,
          bank_name: this.selectedPaymentMode.bank ? this.bankName : '',
          bank_number: this.selectedPaymentMode.bank ? this.bankNumber : '',
          payment_date: '' + this.paymentDate + 'T00:00',
          emitter: this.selectedPaymentMode.showEmitter ? this.emitter : '',
        }
        await backendApi.callApi(data)
        await this.addSuccess('Le paiement a été modifié')
        this.$emit('done')
        this.$bvModal.hide(this.getId())
      } catch (err) {
        this.errorText = this.getErrorText(err)
      }
    },
    async loadPaymentModes() {
      try {
        let url = '/api/payment-modes/'
        let backendApi = new BackendApi('get', url)
        let resp = await backendApi.callApi()
        this.paymentModes = [makePaymentMode()].concat(resp.data.map(elt => makePaymentMode(elt)))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadBanks() {
      this.banks = []
      this.allBanks = []
      this.bankName = ''
      const that = this
      setTimeout(
        () => {
          that.$refs.bankNameTypeAhead.inputValue = ''
        }, 20
      )
      if (
        this.paymentEntity.id && this.selectedPaymentMode && this.selectedPaymentMode.id > 0 &&
        this.selectedPaymentMode.bank
      ) {
        try {
          let url = '/api/banks-list/' + this.paymentEntity.id + '/' + this.selectedPaymentMode.id + '/'
          let backendApi = new BackendApi('get', url)
          let resp = await backendApi.callApi()
          this.banks = resp.data['entity_banks']
          this.allBanks = resp.data['all_banks']
          this.allowMoreBanks = this.allBanks.length > 0
          if (this.banks.length === 0) {
            this.banks = this.allBanks
            this.allowMoreBanks = false
          }
          if (this.banks.length === 1) {
            setTimeout(
              () => {
                that.$refs.bankNameTypeAhead.inputValue = this.banks[0]
              }, 20
            )
          }
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    onBankSelected(evt) {
    },
    onPaymentModeChanged() {
      this.loadBanks()
    },
    showMoreBanks() {
      this.banks = this.allBanks
      this.allowMoreBanks = false
    },
    checkPaymentDate(paymentDate) {
      let diff = moment().diff(moment(paymentDate), 'years')
      return Math.abs(diff) <= 2
    },
  },
  mounted() {
    this.showModal()
  },
}
</script>
<style lang="less">
  .modal-line {
    padding-bottom: 5px;
    border-bottom: solid 1px #f0f0f0;
    margin-bottom: 5px;
  }
  .modal-line:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
  }
  .error-text {
    padding: 20px;
    color: #cc3700;
    background: #e0e0e0;
  }
  .warning2-text {
    padding: 10px;
    margin-bottom: 5px;
    background: #f3e6c7;
    color: #222;
  }
  .footer-buttons a {
    display: inline-block;
    margin-left: 5px;
  }
  .error-field {
    padding: 2px;
    color: #cc3700;
  }
</style>
