<template>
  <div>
    <i
      v-if="canViewNotes && !isHome"
      class="fa notes-icon right-bar-icon"
      title="Notes"
      :class="cssClass"
      v-b-tooltip
      v-b-toggle.sidebar-notes
    ></i>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import store from '@/store'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'

export default {
  name: 'notes-button',
  mixins: [BackendMixin],
  props: {
    updated: Boolean,
  },
  components: {
  },
  data() {
    return {
      notesCount: 0,
      timeout: null,
      loading: false,
    }
  },
  computed: {
    isAuthenticated() {
      return store.getters.isAuthenticated
    },
    isHome() {
      return window.location.pathname === '/'
    },
    canViewNotes() {
      return this.isAuthenticated && this.hasPerm('notes.view_note')
    },
    cssClass() {
      return {
        highlighted: this.notesCount > 0,
        loading: this.loading,
        'fa-pen': this.notesCount > 0,
        'fa-sticky-note': this.notesCount === 0,
      }
    },
  },
  mounted() {
  },
  watch: {
    $route() {
      this.notesCount = 0
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      const that = this
      this.loading = true
      this.timeout = setTimeout(
        function() {
          that.loadNotesCount()
        },
        2000
      )
    },
    updated() {
      if (this.updated) {
        this.loadNotesCount()
      }
    },
  },
  methods: {
    ...mapActions(['addError']),
    async loadNotesCount() {
      this.notesCount = 0
      if (this.isAuthenticated && this.canViewNotes) {
        const url = '/notes/api/notes/count/?path=' + window.location.pathname
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.notesCount = resp.data.count
          this.loading = false
          this.$emit('loaded', { count: this.notesCount, })
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.notes-icon {
  top: 100px;
}
.loading {
  background: #ccc;
}
</style>
