import moment from 'moment'

import { getSafeAge, getAgeValue, getBabyAge, getMonthAgeValue } from '@/utils/dates'
import { cellPhoneForSms } from '@/utils/phones'

export const GENDER_MALE = 1
export const GENDER_FEMALE = 2
export const GENDER_NOT_SET = 0

export class City {
  constructor(
    public id: number,
    public name: string,
    public zipCodes: string[],
    public streetsCount: number
  ) {
  }

  getDepartment(): string {
    if (this.zipCodes.length) {
      return this.zipCodes[0].substring(0, 2)
    }
    return ''
  }
}

export class Gender {
  constructor(
    public id: number,
    public name: string,
    public nameForIndividual: string,
    public nameForChild: string
  ) {
  }
}

export class EntityRole {
  constructor(
    public id: number,
    public name: string,
    public familyRole: boolean, // proposed on family form
    public isMain: boolean, // listed in adults or children
    public isDefault: boolean, // listed in adults or children
    public isChild: boolean, // listed in children
    public isBirtDateRequired: boolean,
    public hasContact: boolean, // show the contact info (phone, email)
    public isLegalRepresentative: boolean, // sélectionnable comme représentant légal
    public gender: number, // default gender : Mother -> Female
    public showChildrenAllowed: boolean,
    public allowComments: boolean,
    public showIndividualLink: boolean,
    public isEmailRecommended: boolean,
    public showAcceptNewsletter: boolean,
    public showBirthDate: boolean,
    public checkChildrenAllowed: boolean
  ) {
  }

  public hasChildrenAllowed(): boolean {
    return !this.isMain && this.familyRole
  }
}

export class MinimalEntity {
  constructor(
    public id: number,
    public name: string,
    public archived: boolean
  ) {
  }
}

export function makeMinimalEntity(jsonData: any = null): MinimalEntity {
  if (jsonData === null) {
    jsonData = {}
  }
  return new MinimalEntity(
    jsonData.id || 0,
    jsonData.name || '',
    !!jsonData.archived
  )
}

export class Individual {
  constructor(
    public id: number,
    public lastName: string,
    public firstName: string,
    public birthDate: Date | null,
    public deathDate: Date | null,
    public gender: Gender,
    public createdOn: Date,
    public updatedOn: Date,
    public about: string,
    public cellPhone: string,
    public proPhone: string,
    public email: string,
    public isNewsletter: boolean,
    public isNewsletterOn: Date|null,
    public childId: number,
    public millibaseNumber: number,
    public entities: MinimalEntity[]
  ) {
  }

  public firstAndLastName(): string {
    const fullName = this.firstName.trim() + ' ' + this.lastName.trim()
    return fullName.trim()
  }

  public lastAndFirstName(): string {
    const fullName = this.lastName.trim() + ' ' + this.firstName.trim()
    return fullName.trim()
  }

  public fullName(): string {
    let prefix
    if (this.gender.id === GENDER_MALE) {
      prefix = 'M. '
    } else if (this.gender.id === GENDER_FEMALE) {
      prefix = 'Mme '
    } else {
      prefix = ''
    }
    return prefix + this.firstAndLastName()
  }

  public isFemale(): boolean {
    return (this.gender.id === GENDER_FEMALE)
  }

  public getAge(): string {
    return getSafeAge(this.birthDate)
  }

  public getBabyAge(): string {
    return getBabyAge(this.birthDate)
  }

  public getMonthAgeValue(): number|null {
    return getMonthAgeValue(this.birthDate)
  }

  public isBaby() {
    return (this.birthDate !== null) && ((getAgeValue(this.birthDate) || 0) <= 4)
  }

  public isNotBaby() {
    return (this.birthDate !== null) && ((getAgeValue(this.birthDate) || 0) >= 3)
  }

  public getBabyAgeOn(onDate: any): string {
    return getBabyAge(this.birthDate, onDate)
  }

  public getAgeOn(onDate: any): number {
    return getAgeValue(this.birthDate, onDate) || 0
  }

  public birthDateAsString(): string {
    return moment(this.birthDate).format('DD/MM/YYYY')
  }

  public birthYear(): number|null {
    if (this.birthDate) {
      return moment(this.birthDate).year()
    }
    return null
  }

  public getMainPhone(): string {
    return this.cellPhone || this.proPhone
  }

  public cellPhoneForSms(): string {
    return cellPhoneForSms(this.cellPhone)
  }
}

export class Student {
  constructor(
    public id: number,
    public individual: Individual,
    public schoolClassUpdatedOn: Date
  ) {
  }
}

export class EntityMembership {
  constructor(
    public id: number,
    public role: EntityRole,
    public individual: Individual,
    public emailContact: boolean,
    public childrenAllowed: boolean,
    public comments: string,
    public legalRepresentativeId: number = 0
  ) {
  }

  public fullName(): string {
    return this.individual.fullName()
  }
}

export class Entity {
  constructor(
    public id: number,
    public archived: boolean,
    public name: string,
    public title: string,
    public phone: string,
    public streetNumber: string,
    public street: string,
    public address2: string,
    public zipCode: string,
    public cedex: string,
    public city: City,
    public about: string,
    public createdOn: Date,
    public updatedOn: Date,
    public millibaseNumber: number,
    public family: any|null,
    public memberships: EntityMembership[],
    public members: string,
    public memberIds: number[],
    public isOwn: boolean, // entité correspondant à la structure,
    public isChecked: boolean // entité modifiée depuis portail activité
  ) {
  }

  public getAllIndividuals(): Individual[] {
    const individuals: Individual[] = []
    const others: Individual[] = []
    for (const membership of this.memberships) {
      if (membership.role.isMain) {
        individuals.push(membership.individual)
      } else {
        others.push(membership.individual)
      }
    }
    return individuals.concat(others)
  }

  public getMainIndividuals(): Individual[] {
    const individuals: Individual[] = []
    for (const membership of this.memberships) {
      if (membership.role.isMain) {
        individuals.push(membership.individual)
      }
    }
    return individuals
  }

  public getParentsIndividuals(): Individual[] {
    const individuals: Individual[] = []
    for (const membership of this.memberships) {
      if (membership.role.isMain && !membership.role.isChild) {
        individuals.push(membership.individual)
      }
    }
    return individuals
  }

  public getParentsContactIndividuals(): Individual[] {
    const individuals: Individual[] = []
    for (const membership of this.memberships) {
      if (membership.role.isMain && !membership.role.isChild && membership.emailContact) {
        individuals.push(membership.individual)
      }
    }
    return individuals
  }

  public getParentsNames(): string {
    const parents: Individual[] = this.getParentsIndividuals()
    const max = parents.length > 2 ? 2 : parents.length
    let familyName = ''
    let name = this.name
    if (parents.length === 1) {
      name = parents[0].firstAndLastName()
    } else if (parents.length >= 2) {
      if (parents[0].lastName === parents[1].lastName) {
        name = [parents[0].firstName, 'et', parents[1].firstName, parents[0].lastName].map(
          text => text.trim()
        ).join(' ')
      } else {
        name = parents[0].firstAndLastName() + ' et ' + parents[1].firstAndLastName()
      }
    }
    return name
  }

  public getChildrenIndividuals(): Individual[] {
    const individuals: Individual[] = []
    for (const membership of this.memberships) {
      if (membership.role.isMain && membership.role.isChild) {
        individuals.push(membership.individual)
      }
    }
    return individuals
  }

  public addressLines(): string[] {
    const lines: string[] = []
    const line1Values = [this.streetNumber, this.street || this.address2]
    lines.push(line1Values.join(' ').trim())
    if (this.street && this.address2 && (this.street !== this.address2)) {
      lines.push(this.address2)
    }
    return lines
  }

  public addressText(): string {
    const lines: string[] = this.addressLines()
    return lines.filter(elt => elt).join(' ')
  }

  public fullAddress(): string {
    const lines: string[] = this.addressLines()
    lines.push(this.zipCode)
    lines.push(this.city.name)
    return lines.filter(elt => elt).join(' ')
  }

  public fullName(): string {
    if (this.title) {
      return this.title + ' ' + this.name
    }
    return this.name
  }
}

export function makeGender(jsonData: any): Gender {
  if (jsonData.id === GENDER_MALE) {
    return new Gender(GENDER_MALE, 'Masculin', 'Monsieur', 'Garçon')
  } else if (jsonData.id === GENDER_FEMALE) {
    return new Gender(GENDER_FEMALE, 'Féminin', 'Madame', 'Fille')
  } else if (jsonData.id === GENDER_NOT_SET) {
    return new Gender(GENDER_NOT_SET, 'Non renseigné', 'Non renseigné', 'Non renseigné')
  } else {
    return new Gender(
      jsonData.id,
      jsonData.name,
      jsonData.nameForIndividual || jsonData.name,
      jsonData.nameForChild || jsonData.name
    )
  }
}

export function makeEntityRole(jsonData: any): EntityRole {
  return new EntityRole(
    jsonData.id || 0,
    jsonData.name || '',
    !!jsonData.family_role,
    !!jsonData.is_main,
    !!jsonData.is_default,
    !!jsonData.is_child,
    !!jsonData.is_birth_date_required,
    !!jsonData.has_contact,
    !!jsonData.is_legal_representative,
    jsonData.gender || 0,
    !!jsonData.show_children_allowed,
    !!jsonData.allow_comments,
    !!jsonData.show_individual_link,
    (!!jsonData.is_main) && (!jsonData.is_child),
    !!jsonData.show_accept_newsletter,
    !jsonData.hide_birth_date,
    !!jsonData.check_children_allowed
  )
}

export function makeIndividual(jsonData: any = null, entityId: number = 0): Individual {
  if (jsonData === null) {
    jsonData = {}
  }
  let gender: Gender
  if (jsonData.gender) {
    gender = makeGender({ id: jsonData.gender, name: jsonData.get_gender_display, })
  } else {
    gender = makeGender({ id: 0, name: '', })
  }
  let entities = []
  if (entityId) {
    entities = [makeMinimalEntity({ id: entityId, })]
  }
  if (jsonData.entities) {
    entities = jsonData.entities.map(
      (jsonElt: any) => makeMinimalEntity(jsonElt)
    )
  }
  return new Individual(
    jsonData.id || 0,
    jsonData.last_name || '',
    jsonData.first_name || '',
    jsonData.birth_date || null,
    jsonData.death_date || null,
    gender,
    jsonData.created_on || null,
    jsonData.updated_on || null,
    jsonData.about || '',
    jsonData.cell_phone || '',
    jsonData.pro_phone || '',
    jsonData.email || '',
    !!jsonData.is_newsletter,
    jsonData.is_newsletter_on || null,
    0,
    jsonData.millibase_number || 0,
    entities
  )
}

export function makeStudent(jsonData: any = null): Student {
  if (jsonData === null) {
    jsonData = {}
  }
  return new Student(
    jsonData.id || 0,
    makeIndividual(jsonData.individual || {}),
    jsonData.school_class_updated_on || ''
  )
}

export function makeEntityMembership(jsonData: any = null, entityId: number = 0): EntityMembership {
  if (jsonData === null) {
    jsonData = {}
  }
  return new EntityMembership(
    jsonData.id || 0,
    makeEntityRole(jsonData.role || {}),
    makeIndividual(jsonData.individual || {}, entityId),
    !!jsonData.email_contact,
    !!jsonData.children_allowed,
    jsonData.comments || ''
  )
}

export function makeCity(jsonData: any): City {
  if (jsonData === null) {
    jsonData = {}
  }
  return new City(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.zip_codes || [],
    jsonData.streets_count || 0
  )
}

export function makeEntity(jsonData: any = null): Entity {
  if (jsonData === null) {
    jsonData = {}
  }
  let city: City|null
  if (jsonData.city) {
    city = makeCity(jsonData.city)
  } else {
    city = makeCity({})
  }
  const memberships = jsonData.memberships || []
  let family = null
  if (jsonData.family) {
    family = { id: jsonData.family, }
  }
  return new Entity(
    jsonData.id || 0,
    !!jsonData.archived,
    jsonData.name || '',
    jsonData.title || '',
    jsonData.phone || '',
    jsonData.street_number || '',
    jsonData.street || '',
    jsonData.address2 || '',
    jsonData.zip_code || '',
    jsonData.cedex || '',
    city,
    jsonData.about || '',
    jsonData.created_on || null,
    jsonData.updated_on || null,
    jsonData.millibase_number || 0,
    family,
    memberships.map((elt: any) => makeEntityMembership(elt)),
    jsonData.members || '',
    jsonData.member_ids || [],
    !!jsonData.is_own,
    !!jsonData.is_checked
  )
}

export class Tag {
  constructor(
    public id: number,
    public name: string
  ) {
  }
}

export function makeTag(jsonData: any): Tag {
  if (jsonData === null) {
    jsonData = {}
  }
  return new Tag(
    jsonData.id || 0,
    jsonData.name || ''
  )
}

export class TagMembership extends Tag {
  constructor(
    public id: number,
    public name: string,
    public comments: string
  ) {
    super(id, name)
  }
}

export function makeTagMembership(jsonData: any): TagMembership {
  if (jsonData === null) {
    jsonData = {}
  }
  return new TagMembership(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.comments || ''
  )
}

export class IndividualEntityMembership {
  constructor(
    public id: number,
    public entity: Entity,
    public role: EntityRole
  ) {
  }

  public asText(): string {
    if (this.role.familyRole) {
      return 'Famille ' + this.entity.name + ' - ' + this.role.name
    } else {
      return this.entity.name + ' - ' + this.role.name
    }
  }
}

export function makeIndividualEntityMembership(jsonData: any = null): IndividualEntityMembership {
  if (jsonData === null) {
    jsonData = {}
  }
  return new IndividualEntityMembership(
    jsonData.id || 0,
    makeEntity(jsonData.entity),
    makeEntityRole(jsonData.role)
  )
}

export class EntityPublicFieldCertificate {
  constructor(
    public id: number,
    public fileName: string
  ) {
  }
}

export function makeEntityPublicFieldCertificate(jsonData: any = null): EntityPublicFieldCertificate {
  if (jsonData === null) {
    jsonData = {}
  }
  return new EntityPublicFieldCertificate(
    jsonData.id || 0,
    jsonData.file_name || ''
  )
}

export class EntityPublicFieldChange {
  constructor(
    public index: number,
    public field: string,
    public prevValue: string,
    public updatedValue: string
  ) {
  }
}

export function makeEntityPublicFieldChange(jsonData: any = null, index: number = 0): EntityPublicFieldChange {
  if (jsonData === null) {
    jsonData = {}
  }
  return new EntityPublicFieldChange(
    index,
    jsonData.field || '',
    jsonData.prev_value || '',
    jsonData.updated_value || ''
  )
}

export class EntityPublicChange {
  constructor(
    public id: number,
    public entity: Entity,
    public createdOn: Date,
    public changes: EntityPublicFieldChange[],
    public errors: string[],
    public certificates: EntityPublicFieldCertificate[]
  ) {
  }
}

export function makeEntityPublicChange(jsonData: any = null): EntityPublicChange {
  if (jsonData === null) {
    jsonData = {}
  }
  const changes = jsonData.changes || []
  const errors = jsonData.errors || []
  const certificates = jsonData.certificates || []
  return new EntityPublicChange(
    jsonData.id || 0,
    makeEntity(jsonData.entity),
    jsonData.created_on,
    changes.map((elt: any, index: number) => makeEntityPublicFieldChange(elt, index)),
    errors,
    certificates.map((elt: any) => makeEntityPublicFieldCertificate(elt))
  )
}
