<template>
  <div v-if="entity !== null && hasViewPerm">
    <div class="content-headline">
      <b-row v-if="showAll && !addMode">
        <b-col>
          <span v-if="entity.id && !entity.isChecked" class="badge badge-danger">
            Famille modifiée depuis le portail activité
          </span>
        </b-col>
        <b-col>
          <div>
            <div class="badges text-right">
              <span :title="getTime(entity.createdOn)" v-b-tooltip>
                <b-badge>Créé le {{ entity.createdOn | dateToString('L') }}</b-badge>
              </span>
              <span :title="getTime(entity.updatedOn)" v-b-tooltip>
                <b-badge>MAJ le {{ entity.updatedOn | dateToString('L') }}</b-badge>
              </span>
            </div>
          </div>
        </b-col>
      </b-row>
      <div class="first-block">
        <b-row>
          <b-col>
            <div v-if="!addMode && !showAll && !showReset">
              <div class="field-line">
                <a
                  class="btn btn-primary btn-xs"
                  href
                  @click.prevent="showEntity()"
                >
                  <i class="fa fa-chevron-left"></i>
                  <span v-if="isFamily">Voir la fiche famille</span>
                  <span v-else>Voir la fiche structure</span>
                </a>
              </div>
              <div class="field-line" v-if="entity.id > 0">
                <div class="header-small-line-entity-name">
                  <span class="family-numbers">{{entity.id}}</span>
                  <span class="family-name">{{ entity.name }}</span>
                </div>
              </div>
            </div>
            <div class="header-bar" v-if="!addMode && showAll">
              <b-row>
                <b-col>
                  <h2 class="small2">
                    <span v-if="entity.id > 0">
                      <span class="family-numbers">{{entity.id}}</span>
                      <span class="family-name">{{ entity.name }}</span>
                    </span>
                    <span class="new-title" v-else>
                      <span v-if="isFamily">Nouvelle famille</span>
                      <span v-else>Nouvelle structure</span>
                    </span>
                  </h2>
                  <span class="entity-adhesions-title">
                    <span v-for="adhesion of adhesions" :key="adhesion.id" class="badge badge-light">
                      {{ adhesion.adhesionType.name }} - {{ adhesion.schoolYear.name }}
                    </span>
                  </span>
                </b-col>
                <b-col cols="2" v-if="!showForm">
                  <span class="badge badge-dark" v-if="familyLevel" :title="familyAllLevels" v-b-tooltip>
                    {{ familyLevel }}
                  </span>
                  <div v-if="family && family.unconfirmedLevels.length">
                    <span class="badge badge-danger" title="à confirmer" v-b-tooltip>
                      {{ familyUnconfirmedLevels }}
                    </span>
                  </div>
                </b-col>
                <b-col cols="2" class="text-right">
                  <a
                    class="btn btn-primary edit-button btn-sm"
                    href
                    @click.prevent="onEdit"
                    v-if="!showForm && hasEditPerm"
                    :class="editDisabled ? 'disabled' : ''"
                  >
                    <i class="fa fa-edit"></i> Modifier
                  </a>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
        <div v-if="!showForm || !showAll">
          <div class="field-line">
            <div>
              <i class="fa fa-address-card"></i> <b v-if="showAll">Titre: </b>
              {{ entity.title | defaultValue('-') }}
            </div>
          </div>
          <div class="field-line">
            <div>
              <i class="fa fa-phone"></i> <b v-if="showAll">Téléphone: </b>
              <phone-link :phone="entity.phone"></phone-link>
            </div>
          </div>
          <div class="field-line">
            <div v-for="(line, index) of entity.addressLines()" :key="line">
              <span v-if="index === 0"><i class="fa fa-home"></i></span>
              {{ line }}
            </div>
            <div>
              {{ entity.zipCode }}
              <span v-if="entity.city">{{ entity.city.name }}</span>
              {{ entity.cedex }}
            </div>
          </div>

          <fields-detail
            :show-entity="true"
            :read-only="true"
            :role="null"
            :individual="null"
            :entity="entity"
            :is-family="!!this.isFamily"
            :included="true"
            :included-in-address="true"
          >
          </fields-detail>

          <div class="field-line" v-if="entity.about">
            <h3><i class="fa fa-info-circle"></i> <b v-if="showAll">Informations complémentaires</b></h3>
            {{ entity.about }}
          </div>
        </div>
        <b-form @submit.prevent="onSave" v-if="showForm && showAll && entityData" class="first-block-form">
          <b-row>
            <b-col cols="6">
              <b-form-group
                id="name-group"
                label="Nom*"
                label-for="name"
                :description="isFamily ? 'Le nom de la famille' : 'Le nom de la structure'"
              >
                <b-form-input
                  id="name"
                  v-model="entityData.name"
                  type="text"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group
                id="title-group"
                label="Titre"
                label-for="title"
                :description="isFamily ? 'Par exemple: Mme et M.' : 'Un préfixe'"
              >
                <b-form-input
                  id="name"
                  v-model="entityData.title"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group
                id="phone-group"
                label="Téléphone"
                label-for="phone"
                :description="'Téléphone de la ' + (isFamily ? 'famille' : 'structure')"
              >
                <phone-input
                  id="phone"
                  @isValid="setPhoneValid"
                  v-model="entityData.phone"
                >
                </phone-input>
                <div v-if="!isPhoneValid" class="field-error">Le N° n'est pas valide</div>
              </b-form-group>
            </b-col>
            <b-col></b-col>
            <b-col></b-col>
          </b-row>

          <b-form-group
            id="address-group"
            description=""
            :class="showAbout ? 'with-separators' : ''"
          >
            <b-row>
              <b-col cols="3">
                <b>Adresse</b>
              </b-col>
              <b-col cols="9">
                <div class="text-right">
                  <b-form-checkbox
                    id="addressRequired"
                    v-model="addressRequired"
                    name="addressRequired"
                    :value="true"
                    :unchecked-value="false"
                  >
                    L'adresse est requise
                  </b-form-checkbox>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  id="city-group"
                  :label="'Ville' + (this.addressRequired ? '*' : '')"
                  label-for="city"
                  description=""
                >
                  <div v-if="addCity">
                    <vue-bootstrap-typeahead
                      v-model="cityName"
                      :data="cityNames"
                      ref="cityTypeAhead"
                      :required="addressRequired"
                    />
                    <div>
                      <a href @click.prevent="addCity = false" class="ut-hide-add-city">
                        <i class="fa fa-list"></i>
                      </a>
                    </div>
                  </div>
                  <div v-else>
                    <b-select
                      v-model="cityName"
                      :required="addressRequired"
                    >
                      <b-select-option
                        v-for="city of cities"
                        :key="city.id"
                        :value="city.name"
                      >
                        {{ city.name }}
                      </b-select-option>
                    </b-select>
                    <div>
                      <a href @click.prevent="addCity = true" :class="dark ? 'light-link' : ''">
                        <i class="fa fa-plus"></i>
                      </a>
                    </div>
                  </div>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="zipCode-group"
                  :label="'Code postal' + (this.addressRequired ? '*' : '')"
                  label-for="zipCode"
                  description=""
                >
                  <vue-bootstrap-typeahead
                    v-model="entityData.zipCode"
                    :data="zipCodes"
                    ref="zipCodeTypeAhead"
                    :minMatchingChars="1"
                    :required="addressRequired"
                  >
                  </vue-bootstrap-typeahead>
                </b-form-group>
              </b-col>

              <b-col>
                <b-form-group
                  id="cedex-group"
                  label="Cedex"
                  label-for="cedex"
                  description=""
                >
                   <b-form-input
                    id="cedex"
                    v-model="entityData.cedex"
                    type="text"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row style="margin-bottom: 10px">
              <b-col cols="2">
                <b-form-input
                  id="streetNumber"
                  v-model="entityData.streetNumber"
                  type="text"
                  placeholder="N°"
                ></b-form-input>
              </b-col>
              <b-col cols="10">
                <vue-bootstrap-typeahead
                  id="street"
                  v-model="entityData.street"
                  :data="streets"
                  :disabled="!cityName"
                  ref="streetTypeAhead"
                  placeholder="Nom de rue"
                  :required="addressRequired && !entityData.address2"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-input
                  v-if="!hideAddress2 || entity.address2"
                  id="address2"
                  v-model="entityData.address2"
                  type="text"
                  placeholder="Complément d'adresse"
                  ></b-form-input>
                </b-col>
            </b-row>
          </b-form-group>

          <b-form-group
            id="about-group"
            label="Informations complémentaires"
            label-for="about"
            description=""
            v-if="showAbout"
          >
            <b-textarea
              id="about"
              v-model="entityData.about"
            ></b-textarea>
          </b-form-group>

          <fields-detail
            :show-entity="true"
            :read-only="false"
            :role="null"
            :individual="null"
            :entity="entity"
            :is-family="!!this.isFamily"
            :included="true"
            :included-in-address="true"
            :force-edit="true"
            :force-save="forceSaveFields"
            @saved="onFieldsSaved"
          >
          </fields-detail>

          <b-row class="buttons-bar" v-if="!addMode">
            <b-col class="text-left" cols="7">
              <span v-if="entity.id && hasDeletePerm">
                <a class="btn btn-danger" href @click.prevent="confirmDelete">
                  Supprimer
                </a>
                &nbsp;
                <a href @click.prevent="showAbout = true" v-if="!showAbout" class="show-about">
                  Ajouter des informations complémentaires
                </a>
                <b-modal id="bv-modal-delete" cancel-title="Annuler" @ok.prevent="onDelete" ok-variant="danger" ok-title="Confirmer">
                  <template v-slot:modal-title>
                    <span v-if="isFamily">Suppression d'une famille</span>
                    <span v-else>Suppression d'une structure</span>
                  </template>
                  <div class="d-block">
                    <span v-if="isFamily">
                      <p>Êtes-vous sûr de supprimer la famille {{ entity.name }}?</p>
                      <p>La famille et tous ses membres seront supprimés</p>
                    </span>
                    <span v-else>
                      <p>Êtes-vous sûr de supprimer la structure {{ entity.name }}?</p>
                      <p>La structure et tous ses membres seront supprimés</p>
                    </span>
                  </div>
                </b-modal>
              </span>
            </b-col>
            <b-col class="text-right">
              <a class="btn btn-secondary cancel-button" href @click.prevent="onCancel">Annuler</a>
              <b-button
                :disabled="!isValid"
                type="submit"
                variant="primary"
              >
                Enregistrer
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import PhoneInput from '@/components/Controls/PhoneInput'
import PhoneLink from '@/components/Controls/Links/PhoneLink'
import { dateToString } from '@/filters/texts'
import { BackendMixin } from '@/mixins/backend'
import router from '@/router'
import store from '@/store'
import { makeFamily } from '@/types/families'
import { makeEntity, makeCity } from '@/types/people'
import { BackendApi } from '@/utils/http'
import FieldsDetail from '@/components/Fields/FieldsDetail.vue'

export default {
  name: 'entity-detail',
  components: {
    FieldsDetail,
    PhoneLink,
    PhoneInput,
  },
  mixins: [BackendMixin],
  props: {
    entity: Object,
    showAll: {
      type: Boolean,
      defaultValue: false,
    },
    initialEditMode: {
      type: Boolean,
      defaultValue: false,
    },
    isFamily: Boolean,
    showReset: {
      type: Boolean,
      defaultValue: false,
    },
    addMode: {
      type: Boolean,
      defaultValue: false,
    },
    forceEntityName: {
      type: String,
      defaultValue: '',
    },
    forceSave: {
      type: Boolean,
      defaultValue: false,
    },
    adhesions: {
      type: Array,
      defaultValue: [],
    },
    dark: {
      type: Boolean,
      defaultValue: false,
    },
  },
  data() {
    return {
      editModeEnabled: true,
      cities: [],
      streets: [],
      cityNames: [],
      zipCodes: [],
      entityData: null,
      cityName: '',
      addressRequired: true,
      isPhoneValid: true,
      addCity: false,
      showAbout: false,
      showForm: false,
      forceSaveFields: false,
    }
  },
  computed: {
    editDisabled() {
      return this.addMode ? false : (store.getters.editMode !== '' && store.getters.editMode !== 'entity')
    },
    family() {
      if (this.entity && this.entity.family) {
        return this.entity.family
      }
      return null
    },
    familyLevel() {
      if (this.family) {
        if (this.family.mainLevel()) {
          return 'QF ' + this.family.mainLevel()
        } else {
          return 'QF non défini'
        }
      }
      return ''
    },
    familyAllLevels() {
      if (this.family) {
        const familyLevelsCount = store.getters.familyLevelsCount
        const levels = this.family.levels.slice(0, familyLevelsCount)
        if (levels.length > 1) {
          return this.family.levels.filter(
            level => level.order > 1
          ).map(
            level => 'QF' + level.order + ': ' + level.value
          ).join(' ')
        }
      }
      return ''
    },
    familyUnconfirmedLevels() {
      if (this.family) {
        const showOrder = store.getters.familyLevelsCount > 1
        return this.family.unconfirmedLevels.map(
          level => 'QF' + (showOrder ? level.order : '') + ': ' + level.value
        ).join(' ')
      }
      return ''
    },
    hideAddress2() {
      return store.getters.hideAddress2
    },
    hasViewPerm() {
      if (this.isFamily) {
        return this.hasPerm('families.view_familyentity')
      } else {
        return this.hasPerm('people.view_contact')
      }
    },
    hasEditPerm() {
      if (this.isFamily) {
        return this.hasPerm('families.change_familyentity')
      } else {
        return this.hasPerm('people.change_contact')
      }
    },
    hasAddPerm() {
      if (this.isFamily) {
        return this.hasPerm('families.add_familyentity')
      } else {
        return this.hasPerm('people.add_contact')
      }
    },
    hasDeletePerm() {
      if (this.isFamily) {
        return this.hasPerm('families.delete_familyentity')
      } else {
        return this.hasPerm('people.delete_contact')
      }
    },
    isValid() {
      let isValid = (this.entityData && this.isPhoneValid && this.entityData.name)
      if (isValid && this.addressRequired) {
        isValid = this.cityName && this.entityData.zipCode && (this.entityData.address2 || this.entityData.street)
      }
      return isValid
    },
  },
  created() {
    this.init()
  },
  watch: {
    initialEditMode() {
      this.init()
    },
    forceEntityName(value) {
      if (value && this.entityData && !this.entityData.name) {
        this.entityData.name = value
      }
    },
    forceSave(value) {
      if (value) {
        this.onSave()
      }
    },
    addressRequired: function(isRequired) {
      if (isRequired && !this.cityName) {
        this.loadDefaultCity()
      }
    },
    cityName: function(newCityName, oldCityName) {
      if (this.entityData) {
        let zipCodeChanged = false
        if (oldCityName) {
          this.entityData.zipCode = ''
          zipCodeChanged = true
        }
        if (this.cities.length) {
          let index = this.cities.map(elt => elt.name).indexOf(newCityName)
          if (index >= 0) {
            let selectedCity = this.cities[index]
            this.zipCodes = selectedCity.zipCodes
            if ((this.entityData.zipCode === '') && (this.zipCodes.length > 0)) {
              this.entityData.zipCode = this.zipCodes[0]
              zipCodeChanged = true
            }
            this.loadStreets(selectedCity)
          } else {
            this.streets = []
          }
        }
        if (zipCodeChanged) {
          setTimeout(
            () => {
              this.$refs.zipCodeTypeAhead.inputValue = this.entityData.zipCode
            }, 100
          )
        }
      }
    },
    addCity: function() {
      setTimeout(
        () => {
          if (this.$refs.cityTypeAhead) {
            this.$refs.cityTypeAhead.inputValue = this.cityName
          }
        },
        100
      )
    },
    isValid: function(value) {
      this.$emit('is-valid-changed', { isValid: !!value, })
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading', 'setEditMode']),
    init() {
      if (this.initialEditMode) {
        this.onEdit()
      } else {
        this.showForm = false
      }
    },
    setPhoneValid(event) {
      this.isPhoneValid = event
    },
    showEntity() {
      this.$emit('show-entity')
    },
    onEdit() {
      if (!this.editDisabled) {
        this.showForm = true
        if (!this.addMode) {
          this.setEditMode('entity')
        }
        // deep copy
        if (this.entity) {
          this.entityData = { ...this.entity, }
          this.entityData.city = { ...this.entity.city, }
          this.cityName = this.entity.city.name
          this.zipCodes = this.entity.city.zipCodes
          this.showAbout = !!this.entity.about
          this.loadCities()
          if (this.entityData.id === 0) {
            // New entity : try to set the default city
            this.loadDefaultCity()
          } else {
            if (this.entity.city.id > 0) {
              this.loadStreets(this.entity.city)
            }
            setTimeout(
              () => {
                if (this.$refs.cityTypeAhead) {
                  this.$refs.cityTypeAhead.inputValue = this.entityData.city.name
                }
                this.$refs.zipCodeTypeAhead.inputValue = this.entityData.zipCode
                this.$refs.streetTypeAhead.inputValue = this.entityData.street
              }, 100
            )
          }
          if (!this.isFamily) {
            this.addressRequired = false
          }
        }
      }
    },
    onCancel() {
      this.showForm = false
      if (!this.addMode) {
        this.setEditMode('')
      }
      if (!this.entity.id) {
        this.getBack()
      }
    },
    async onSave() {
      if (!this.isPhoneValid) {
        return
      }
      const data = {
        name: this.entityData.name,
        phone: this.entityData.phone,
        street_number: this.entityData.streetNumber,
        street: this.entityData.street,
        address2: this.entityData.address2,
        cedex: this.entityData.cedex,
        zip_code: this.entityData.zipCode,
        city: this.cityName,
        about: this.entityData.about,
        title: this.entityData.title,
      }
      let backendApi = null
      let baseUrl = this.getUrlBase()
      const addMode = !this.entity.id
      if (addMode) {
        backendApi = new BackendApi('post', baseUrl)
      } else {
        backendApi = new BackendApi('patch', baseUrl + this.entity.id + '/')
      }
      this.startLoading('entity')
      try {
        let resp = await backendApi.callApi(data)
        if (this.isFamily) {
          const family = makeFamily(resp.data)
          this.$emit('saved', { family: family, entity: family.entity, })
        } else {
          const entity = makeEntity(resp.data)
          this.$emit('saved', { family: null, entity: entity, })
        }
        if (!this.forceSave) {
          if (this.isFamily) {
            await this.addSuccess('La famille a été enregistrée')
          } else {
            await this.addSuccess('La structure a été enregistrée')
          }
        }
        this.forceSaveFields = true
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading('entity')
    },
    confirmDelete() {
      this.$bvModal.show('bv-modal-delete')
    },
    async onDelete() {
      this.$bvModal.hide('bv-modal-delete')
      this.startLoading('entity')
      let backendApi = new BackendApi('delete', this.getUrlBase() + this.entity.id + '/')
      try {
        await backendApi.callApi()
        if (this.isFamily) {
          await this.addSuccess('La famille a été supprimée')
        } else {
          await this.addSuccess('La structure a été supprimée')
        }
        this.getBack()
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading('entity')
    },
    async loadCities() {
      let backendApi = new BackendApi('get', '/api/people/cities/')
      this.startLoading('entity')
      try {
        let resp = await backendApi.callApi()
        this.cities = resp.data.map(elt => makeCity(elt))
        this.cityNames = this.cities.map(elt => elt.name)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading('entity')
    },
    async loadDefaultCity() {
      let backendApi = new BackendApi('get', '/api/people/default-city/')
      try {
        let resp = await backendApi.callApi()
        let city = makeCity(resp.data)
        await this.loadStreets(city)
        this.cityName = city.name
        setTimeout(
          () => {
            if (this.$refs.cityTypeAhead) {
              this.$refs.cityTypeAhead.inputValue = this.cityName
            }
            if (city.zipCodes.length > 0) {
              this.entityData.zipCode = city.zipCodes[0]
              this.$refs.zipCodeTypeAhead.inputValue = city.zipCodes[0]
            }
          }, 100
        )
      } catch (err) {
        if (err.response && err.response.status === 404) {
          // no default city
        } else {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    async loadStreets(city) {
      this.streets = []
      if (city.id > 0) {
        let backendApi = new BackendApi('get', '/api/people/streets/' + city.id + '/')
        try {
          let resp = await backendApi.callApi()
          this.streets = resp.data.map(elt => elt.name)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    getUrlBase() {
      let baseUrl = '/api/people/contacts/'
      if (this.isFamily) {
        baseUrl = '/api/families/families/'
      }
      return baseUrl
    },
    getBack() {
      if (this.isFamily) {
        router.push({ name: 'families-list', })
      } else {
        router.push({ name: 'entities-list', })
      }
    },
    getTime(value) {
      if (value) {
        return dateToString(value, 'LT')
      }
      return ''
    },
    async onFieldsSaved() {
      this.forceSaveFields = false
      if (!this.forceSave) {
        this.setEditMode('')
        this.showForm = false
        const addMode = !this.entity.id
        if (addMode) {
          if (this.isFamily) {
            await router.push({ name: 'families-detail', params: { entityId: this.entity.id, }, })
          } else {
            await router.push({ name: 'entities-detail', params: { entityId: this.entity.id, }, })
          }
        }
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
#address1.form-control {
  margin-bottom: 5px;
}
.family-name {
  display: inline-block;
  vertical-align: top;
}
.entity-adhesions-title {
  display: inline-block;
  margin-left: 5px;
  vertical-align: top;
}
.header-small-line {
  padding: 5px 0;
  margin-bottom: 5px;
  border-bottom: solid 1px #f0f0f0;
}
.header-small-line-entity-name {
  font-weight: bold;
  padding: 2px 5px;
}
</style>
