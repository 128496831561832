import Vue from 'vue'
import Vuex from 'vuex'

import auth from './auth'
import config from './config'
import fields from './fields'
import loading from './loading'
import messages from './messages'
import people from './people'
import vaccines from './vaccines'
import permissions from './permissions'
import preferences from './preferences'
import screens from './screens'
import settings from './settings'
import nav from './nav'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    config,
    fields,
    loading,
    messages,
    people,
    vaccines,
    permissions,
    preferences,
    screens,
    settings,
    nav,
  },
})
